// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-legal-js": () => import("./../src/templates/Legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-news-listing-js": () => import("./../src/templates/NewsListing.js" /* webpackChunkName: "component---src-templates-news-listing-js" */),
  "component---src-templates-news-article-js": () => import("./../src/templates/NewsArticle.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-project-listing-js": () => import("./../src/templates/ProjectListing.js" /* webpackChunkName: "component---src-templates-project-listing-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-insight-js": () => import("./../src/templates/Insight.js" /* webpackChunkName: "component---src-templates-insight-js" */),
  "component---src-templates-insight-listing-js": () => import("./../src/templates/InsightListing.js" /* webpackChunkName: "component---src-templates-insight-listing-js" */),
  "component---src-templates-insight-tag-listing-js": () => import("./../src/templates/InsightTagListing.js" /* webpackChunkName: "component---src-templates-insight-tag-listing-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-unpublished-insight-js": () => import("./../src/pages/unpublishedInsight.js" /* webpackChunkName: "component---src-pages-unpublished-insight-js" */),
  "component---src-pages-unpublished-legal-js": () => import("./../src/pages/unpublishedLegal.js" /* webpackChunkName: "component---src-pages-unpublished-legal-js" */),
  "component---src-pages-unpublished-news-article-js": () => import("./../src/pages/unpublishedNewsArticle.js" /* webpackChunkName: "component---src-pages-unpublished-news-article-js" */),
  "component---src-pages-unpublished-project-js": () => import("./../src/pages/unpublishedProject.js" /* webpackChunkName: "component---src-pages-unpublished-project-js" */),
  "component---src-pages-unsupported-js": () => import("./../src/pages/unsupported.js" /* webpackChunkName: "component---src-pages-unsupported-js" */)
}

