import React from "react";
import Helmet from "react-helmet";
import FaktumRegularWoff2 from "../../fonts/Faktum/faktum-regular.woff2";
import FaktumSemiBoldWoff2 from "../../fonts/Faktum/faktum-semibold.woff2";
import NeuRegularWoff2 from "../../fonts/NeueHaasUnica/neuehassunica-regular.woff2";
import NeuBoldWoff2 from "../../fonts/NeueHaasUnica/neuehassunica-bold.woff2";

const Preloader = () => {
    return (
        <Helmet >
            <link rel={"preload"} href={FaktumRegularWoff2} as={"font"} type="font/woff2" crossOrigin={"anonymous"} />
            <link rel={"preload"} href={FaktumSemiBoldWoff2} as={"font"} type="font/woff2" crossOrigin={"anonymous"} />
            <link rel={"preload"} href={NeuRegularWoff2} as={"font"} type="font/woff2" crossOrigin={"anonymous"} />
            <link rel={"preload"} href={NeuBoldWoff2} as={"font"} type="font/woff2" crossOrigin={"anonymous"} />
        </Helmet>
    );
}

export default Preloader;