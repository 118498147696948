import React, { createContext, useContext, Component } from "react"

const InsightsStoreContext = createContext()
const { Provider } = InsightsStoreContext

export const useInsightsStoreContext = () => {
    return useContext(InsightsStoreContext)
}


export class InsightsStore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isInitializing: this.isInitializing,
            updateState: this.updateState,
            loadMore: this.loadMore,
            hasMore: this.hasMore
        }
    }
    render() {
        const {children} = this.props;
        return <Provider value={this.state}>{children}</Provider>
    }


    isInitializing = (baseUrl) => {
        return ((this.state[`${baseUrl}/cursor`] || 0) === 0)
    }

    updateState = (mergeableStateObject) => {
        this.setState(mergeableStateObject)
    }

    loadMore = (baseUrl) => {
        if(this.state[`${baseUrl}/loading`]) {
            return false;
        }
        const pageNum = this.state[`${baseUrl}/cursor`]
        this.setState(state => ({
            [`${baseUrl}/cursor`]: state[`${baseUrl}/cursor`]+1,
            [`${baseUrl}/loading`]: true
        }))
        fetch(`${__PATH_PREFIX__}/paginationJson/${baseUrl}/${pageNum}.json`)
            .then(res => res.json())
            .then(
                res => {
                    this.setState({
                        [`${baseUrl}/${pageNum}`]: res,
                        [`${baseUrl}/shouldInfiniteLoad`]: (pageNum - 1) % 3 !== 0,
                        [`${baseUrl}/loading`]: false
                    })
                },
                error => {

                }
            )
    }

    hasMore = (totalPages, baseUrl) => {
        if (this.isInitializing(baseUrl)) return true
        return this.state[`${baseUrl}/cursor`] <= totalPages
    }

}

export default InsightsStore
