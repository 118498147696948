import { isBrowser } from "@emotion/core/src/utils";

export function pageView(path, title) {
    if (isBrowser && window._mtm) {
        window._mtm.push({
            event: "VirtualPageview",
            virtualPageURL: path,
            virtualPageTitle: title,
        });
    }
}

export function track(data) {
    if (isBrowser && window._mtm) {
        window._mtm.push(data);
    }
}
