import React, { useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { Transition, TransitionGroup } from "react-transition-group";
import { Global } from "@emotion/core";
import reset from "../styles/reset";
import faktum from "../styles/fonts/faktum";
import neueHaasUnica from "../styles/fonts/neue-haas-unica";
import Preloader from "./General/Preloader";
import InsightsStore from "./Insights/InsightsStore";
import Optimize from "./Context/Optimize";

const timeout = 300;
const getTransitionStyles = {
    entering: {
        position: "absolute",
        opacity: 0,
    },
    entered: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 1,
    },
    exiting: {
        transition: `all ${timeout}ms ease-in-out`,
        opacity: 0,
    },
};
const Layout = ({ children, location }) => {
    const [variant, setVariant] = useState({});
    return (
        <div>
            <Optimize.Provider value={variant}>
                <Preloader />
                <Global
                    styles={[
                        reset,
                        faktum,
                        neueHaasUnica,
                        {
                            body: {
                                backgroundColor: "white",
                                color: "#001428",
                                fontFamily: `"Neue Haas Unica", sans-serif`,
                            },
                        },
                        {
                            "#CybotCookiebotDialog": {
                                "*": {
                                    fontFamily: "Neue Haas Unica !important",
                                },
                            },
                        },
                    ]}
                />
                <InsightsStore>
                    <TransitionGroup>
                        <Transition
                            key={location.pathname}
                            timeout={{ enter: timeout, exit: timeout }}
                        >
                            {status => {
                                return (
                                    <div
                                        className={status}
                                        style={{
                                            ...getTransitionStyles[status],
                                        }}
                                    >
                                        {children}
                                    </div>
                                );
                            }}
                        </Transition>
                    </TransitionGroup>
                </InsightsStore>
            </Optimize.Provider>
        </div>
    );
};
export default process.env.NODE_ENV === "development" ? hot(Layout) : Layout;
